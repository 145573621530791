<template>
    
        <v-virtual-scroll
            v-if="visibleItems.length > 0"
            :items="visibleItems"
            :item-height="40"
            :height="400"
            :bench="10"
            class="virt-scrl-list-tree"
        >
            <template v-slot:default="{ item, index }">
                
                <v-list-item
                    :key="index"
                    @click="onItemClick(item)"
                    :style="{ marginLeft: (item.level - 1) * 25 + 'px' }"
                    v-ripple="{ class: item.active || item.hasChildren ? `primary--text` : `red--text` }"
                >
                    <v-list-item-action
                        :class="[item.level == 1 ? 'group-item-action' : '', item.hasChildren && item.level > 1 ? 'item-with-arrow' : '']"
                    >

                        <v-icon
                            v-if="item.hasChildren"
                            small
                        >
                            {{ item.collapsed ? 'fas fa-caret-right' : 'fas fa-caret-down' }}
                        </v-icon>

                        <!--<div v-else class="fake-arrow-dropdown"></div>-->

                        <v-checkbox
                            v-if="!item.hasChildren && item.active"
                            color="primary"
                            hide-details
                            class="p-0"
                            :input-value="isItemSelected(item)"
                            @click.native.capture="onItemSelect(item, $event)"
                        >
                        </v-checkbox>
                        <v-checkbox
                            v-else-if="allowParentSelection && item.active"
                            color="primary"
                            hide-details
                            class="p-0 allow-parent-selection-checkbox"
                            :input-value="isParentItemSelected(item)"
                            @click.native.capture="onParentItemSelect(item, $event)"
                        >
                        </v-checkbox>
                        <div v-else-if="(item.level > 1 && !item.hasChildren) || allowParentSelection" class="fake-checkbox"></div>
                        <!--<div v-else-if="item.level > 1 && !item.hasChildren" class="fake-checkbox"></div>
                        <div v-else-if="item.level > 1 && item.hasChildren" class="fake-checkbox-has-child"></div>-->
                                                    

                    </v-list-item-action>

                    <v-list-item-icon
                        v-if="item.level > 1"
                    >
                        <v-icon 
                            v-if="item.position == 0"
                            color="red darken-2"
                            v-tooltip.top-center='$t("Руководитeль_организации")'
                            small
                        >
                            mdi-account-tie
                        </v-icon>

                        <v-icon
                            v-else-if="item.position == 1"
                            color="red darken-2"
                            v-tooltip.top-center='$t("Заместитель_руководителя_организации")'
                            small
                        >
                            mdi-account
                        </v-icon>

                        <v-icon
                            v-else-if="item.position == 2"
                            color="green darken-2"
                            v-tooltip.top-center='$t("Руководитель_подразделения")'
                            small
                        >
                            mdi-account-tie
                        </v-icon>

                        <v-icon
                            v-else-if="item.position == 3"
                            color="green darken-2"
                            v-tooltip.top-center='$t("Заместитель_руководителя_подразделения")'
                            small
                        >
                            mdi-account
                        </v-icon>

                        <v-icon
                            v-else-if="item.element_type == 'Department'"
                            v-tooltip.top-center='$t("Подразделение")'
                            small
                        >
                            mdi-account-group
                        </v-icon>

                        <v-icon
                            v-else-if="item.element_type == 'WorkPlace' || item.element_type == 'Person'"
                            small
                        >
                            mdi-account
                        </v-icon>

                        <v-icon
                            v-else-if="item.element_type == 'Enterprise' || item.element_type == 'Contractor'"
                            small
                        >
                            fas fa-landmark
                        </v-icon>

                    </v-list-item-icon>

                    <v-list-item-content v-if="item.hasChildren">

                        <v-list-item-title>
                            <span
                                :class="[item.level > 1 ? 'font-weight-medium' : 'font-weight-bold', 'text-uppercase']"
                            >
                                {{ item.level > 1 ? item.name : $t(item.name) }}
                            </span>
                        </v-list-item-title>

                    </v-list-item-content>
                    <v-list-item-content v-else>
                        <v-list-item-title>
                            <div class="d-flex">

                                <!-- Наименование -->
                                <div
                                    class="d-flex align-center flex-grow-1"
                                >
                                    <div class="etw-text-container" v-tooltip.top-center='item.name ? item.name : ""'>
                                        <span class="etw-text text-uppercase" v-html="highlight(item.name)"></span>
                                    </div>
                                </div>

                                <!-- Должность -->
                                <div
                                    class="d-flex align-center flex-grow-0"
                                    style="width: 33.333%;"
                                >
                                    <div class="etw-text-container" v-tooltip.top-center='item.jobtitle ? item.jobtitle : ""'>
                                        <span class="etw-text">
                                            {{ item.jobtitle }}
                                        </span>
                                    </div>
                                </div>
                                
                                <!-- Контакты -->
                                <div
                                    class="d-flex align-center flex-grow-0 vslt-contact-col"
                                    style="width: 33.333%;"
                                >
                                    <template v-if="item.contacts">
                                        <v-contact-item
                                            v-for="contact in item.contacts"
                                            :key="contact.id"
                                            :type="contact.type === 'email' ? 'email' : 'phone'"
                                            :title="contact.value"
                                            :description="contact.description"
                                            :hide-load="true"
                                            :is-read-only="true"
                                        />
                                    </template>
                                </div>

                            </div>
                        </v-list-item-title>
                    </v-list-item-content>

                </v-list-item>
                
            </template>

        </v-virtual-scroll>
        <div
            v-else
            class="div-table-row"
        >
            <div class="cfl-placeholder">{{ $t("К_сожалению,_по_Вашему_запросу_ничего_не_найдено") }}</div>
        </div>
    
</template>

<script>
import sys from '@/services/system';

export default {
    name: "VirtualScrollTree",
    props: {
        items: {
            type: Array,
            default: () => []
        },
        selectedIds: {
            type: Array,
            default: () => []
        },
        inactiveIds: {
            type: Array,
            default: () => []
        },
        allowParentSelection: {
            type: Boolean,
            default: false
        },
        expandAll: {
            type: Boolean,
            default: false
        },
        filterString: {
            type: String,
            default: ""
        }
    },
    computed: {
        initItems() {
            const flatten = (items, level = 1, parent = null) => items.reduce(
                (acc, v) => {

                    v.level = level;
                    v.parent = parent;
                    v.visible = v.parent != null ? !v.parent.collapsed : true;
                    v.active = !this.inactiveIds.includes(v.id) && v.allow;
                    v.hasChildren = !!(Array.isArray(v.children) && v.children.length);
                    v.filtered = false;

                    acc.push(v);

                    if (v.hasChildren) {
                        v.collapsed = this.expandAll ? false : v.level > 1 ? true : false;
                        acc.push(...flatten(v.children, level + 1, v));
                    }

                    return acc;
                }, []);

            console.warn('INIT_ITEMS_CALL!');

            return flatten(JSON.parse(JSON.stringify(this.items)), 1);
        },
        visibleItems() {

            if (!this.filterString || this.filterString.length < 3)
                return this.internalList.filter(x => x.visible);

            return this.internalList.filter(x => x.visible && x.filtered);
        }
    },
    data() {
        return {
            internalList: [],
        }
    },
    methods: {
        onItemClick(item) {
            const changeItemsVisibility = (item, visible, state) => {
                    
                item.visible = item.parent ? !item.parent.collapsed && visible : visible;

                if (item.hasChildren) {

                    var i = 0, len = item.children.length;
                    // итерируем список
                    while (i < len) {
                        //отмечаем итерируемый элемент как выбранный
                        changeItemsVisibility(item.children[i], !state, state);
                        i++;
                    }
                }
            };

            if (!item) return;

            let internalListItem = this.internalList.find(x => x.id == item.id);

            if (!internalListItem) return;
            
            if (!item.hasChildren) {
                this.onItemSelect(item);
                return;
            }

            internalListItem.collapsed = !internalListItem.collapsed;
            changeItemsVisibility(internalListItem, true, internalListItem.collapsed);
        },
        onItemSelect(item, event) {

            if (!item) return;

            let internalListItem = this.internalList.find(x => x.id == item.id);

            if (!internalListItem || !internalListItem.active) return;

            this.$emit('on-select', { select: !this.selectedIds.includes(internalListItem.id), value: internalListItem });
            
            if (event)
                event.stopPropagation();
        },
        isItemSelected(item) {
            return this.selectedIds.includes(item.id)
        },
        isParentItemSelected(item) {
            return item.children.filter(x => x.active).every(x => this.selectedIds.includes(x.id));
        },
        onParentItemSelect(item, event) {

            this.$emit('on-select', { select: !this.isParentItemSelected(item), value: item });

            if (event)
                event.stopPropagation();
        },
        applyFilterString() {

            let isEmptySearch = !this.filterString || this.filterString.length < 3;
            var i = 0, len = this.internalList.length;

            while (i < len) {

                if (isEmptySearch) {

                    this.internalList[i].filtered = false;
                    this.internalList[i].visible = this.internalList[i].parent != null ? !this.internalList[i].parent.collapsed : true;

                    if (this.internalList[i].hasChildren)
                        this.internalList[i].collapsed = this.expandAll ? false : this.internalList[i].level > 1 ? true : false;

                }
                else {

                    if (!this.internalList[i].hasChildren)
                        this.internalList[i].filtered = sys.checkSearch(this.internalList[i].name, this.filterString);
                    else
                        this.internalList[i].filtered = false;

                    this.internalList[i].visible = this.internalList[i].filtered;

                    if (this.internalList[i].filtered) {

                        let tmp = this.internalList[i].parent;

                        while (tmp != null) {
                            tmp.filtered = true;
                            tmp.visible = true;
                            tmp.collapsed = false;
                            tmp = tmp.parent;
                        }
                    }

                }

                i++;
            }
        },
        highlight(content) {
            if (!this.filterString)
                return content;

            return String(content).replace(new RegExp(this.filterString, "gi"), match => {
                return `<span style="background:#C5E1A5">${match}</span>`;
            });
        },
    },
    watch: {
        items: {
            handler(v) {
                console.log("handler", v);
                this.internalList = this.initItems;
                this.applyFilterString();
            },
            deep: true
        },
        filterString() {
            this.applyFilterString();
        }
    },
    mounted () {
        console.log("mounted", this._uid);
        this.internalList = this.initItems;
        this.applyFilterString();
    },
}
</script>